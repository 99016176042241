// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-agb-patient-innen-tsx": () => import("./../../../src/pages/agb-patient_innen.tsx" /* webpackChunkName: "component---src-pages-agb-patient-innen-tsx" */),
  "component---src-pages-agb-therapeut-innen-tsx": () => import("./../../../src/pages/agb-therapeut_innen.tsx" /* webpackChunkName: "component---src-pages-agb-therapeut-innen-tsx" */),
  "component---src-pages-bestaetigung-tsx": () => import("./../../../src/pages/bestaetigung.tsx" /* webpackChunkName: "component---src-pages-bestaetigung-tsx" */),
  "component---src-pages-datenschutz-app-tsx": () => import("./../../../src/pages/datenschutz-app.tsx" /* webpackChunkName: "component---src-pages-datenschutz-app-tsx" */),
  "component---src-pages-datenschutzerklaerung-tsx": () => import("./../../../src/pages/datenschutzerklaerung.tsx" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-fehler-tsx": () => import("./../../../src/pages/fehler.tsx" /* webpackChunkName: "component---src-pages-fehler-tsx" */),
  "component---src-pages-fragebogen-tsx": () => import("./../../../src/pages/fragebogen.tsx" /* webpackChunkName: "component---src-pages-fragebogen-tsx" */),
  "component---src-pages-funktionen-patient-innen-tsx": () => import("./../../../src/pages/funktionen/patient_innen.tsx" /* webpackChunkName: "component---src-pages-funktionen-patient-innen-tsx" */),
  "component---src-pages-funktionen-psychotherapeut-innen-tsx": () => import("./../../../src/pages/funktionen/psychotherapeut_innen.tsx" /* webpackChunkName: "component---src-pages-funktionen-psychotherapeut-innen-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-informationen-datenverarbeitung-tsx": () => import("./../../../src/pages/informationen-datenverarbeitung.tsx" /* webpackChunkName: "component---src-pages-informationen-datenverarbeitung-tsx" */),
  "component---src-pages-informiert-bleiben-tsx": () => import("./../../../src/pages/informiert-bleiben.tsx" /* webpackChunkName: "component---src-pages-informiert-bleiben-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-neuigkeiten-tsx": () => import("./../../../src/pages/neuigkeiten.tsx" /* webpackChunkName: "component---src-pages-neuigkeiten-tsx" */),
  "component---src-pages-newsletter-bestaetigung-tsx": () => import("./../../../src/pages/newsletter-bestaetigung.tsx" /* webpackChunkName: "component---src-pages-newsletter-bestaetigung-tsx" */),
  "component---src-pages-ueber-uns-tsx": () => import("./../../../src/pages/ueber-uns.tsx" /* webpackChunkName: "component---src-pages-ueber-uns-tsx" */),
  "component---src-pages-widerrufsbelehrung-tsx": () => import("./../../../src/pages/widerrufsbelehrung.tsx" /* webpackChunkName: "component---src-pages-widerrufsbelehrung-tsx" */)
}

